<template>
  <v-row class="text-center primary">
    <v-col cols="12" class="mt-16">
      <v-row class="d-flex justify-center mt-16">
        <v-col cols="12">
          <img
            src="@/assets/images/logos/logo-vumetric-telus-white.svg"
            height="150"
            alt="Vumetric logo"
            class=""
          >
        </v-col>
        <v-col cols="12" class="mb-14">
          <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
          />
        </v-col>
        <v-col cols="3">
          <v-card
            flat
            color="mt-10 pa-4"
            class="primary overflow-hidden"
          >
            <h5 v-if="textInfo === 1" class="animation1 white--text">Um... There seems to be a problem with your connection !</h5>
            <h5 v-if="textInfo === 2" class="animation1 white--text">You will be redirected to the login page</h5>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import UserService from "@/services/user.service"
// import SnackBar from "@/components/base/SnackBar"
import AuthService from "@/services/auth.service"
import snackbarPlugin from '@/plugins/snackbar'

export default {
  data: () => ({
    interval: {},
    value: 0,
    textInfo: 0
  }),
  mounted() {
    setTimeout(() => {
      this.textInfo = 1
      setTimeout(() => {
        this.textInfo = 2
        setTimeout(() => {
          this.$router.push("/login")
        }, 5000)
      }, 5000)
    }, 60000)
    const urlParams = new URLSearchParams(window.location.search)
    const actionType = window.location.pathname
      .replace("/", "")
      .replace("/sso", "")
      .replace("/azure", "")
      .replace("/google", "")
    const provider = window.location.pathname
      .replace("/login", "")
      .replace("/register", "")
      .replace("/sso/", "")

    if (actionType === "login") {
      this.ssoAccessToken(provider, {
        code: urlParams.get("code"),
        state: urlParams.get("state"),
        session_state: urlParams.get("session_state")
      })
    }
    if (actionType === "register") {
      this.ssoRegisterToken(provider, {
        code: urlParams.get("code"),
        state: urlParams.get("state"),
        session_state: urlParams.get("session_state")
      })
    }

    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0)
      }
      this.value += 10
    }, 500)
  },
  methods: {
    async ssoAccessToken(provider, data) {
      data.provider = this.$route.params.provider
      this.$store.dispatch("auth/ssoLogin", data).then(
        (resp) => {
          UserService.getProfile().then((profile) => {
            this.$store.commit("auth/setProfile", profile)
            // this.$router.push("/dashboard")
            setTimeout(() => {
              this.$router.go()
            }, 700)
          })
        },
        (error) => {
          if (error.response.data.detail) {
            snackbarPlugin.showError(error.response.data.detail)
          }
          this.$router.push('/login')
        }
      )
    },
    async ssoRegisterToken(provider, data) {
      const res = await AuthService.ssoRegisterToken(provider, data)

      if (res === 200 || res === 201) {
        window.location.href = "/"
      } else {
        window.location.href = "/login"
      }
    }
  }
}
</script>

<style lang="scss">
.animation1 {
  animation: TextInfoDown 5s infinite ease-in-out;
  }
@keyframes TextInfoDown {
  0% {
    opacity: 0;
    transform: translateX(400px);
  }
  10% {
    opacity: 1;
    transform: translateX(0px);
  }
  90% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-400px);
  }
}
</style>
